import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Link from "next/link";

const NavigationItem = ({ icon, label, active, url, count, isNew }) => {
  return (
    <Link
      href={url}
      className={`footerbar__nav-item ${
        active ? "footerbar__nav-item--active" : ""
      } `}
    >
      <div className="footerbar__icon">
        <IconButton style={{ color: "#5b5b5b" }}>{icon}</IconButton>
        {count > 0 && <span className="footerbar__badge">{count}</span>}
        {isNew && <span className="footerbar__new">Mới</span>}
      </div>
      <label>{label}</label>
    </Link>
  );
};

export default React.memo(NavigationItem);
