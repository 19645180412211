// import NavigationData from "./navigation-data";
import NavigationItem from "./navigation-item";

import React, { useMemo } from "react";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import FaceIcon from "@material-ui/icons/Face";
import HomeIcon from "@material-ui/icons/Home";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import RssFeedIcon from "@material-ui/icons/RssFeed";

const FooterBar = props => {
  const Router = useRouter();
  const unReadTopics = useSelector(({ common }) => common.unreadTopics);
  const navData = useMemo(
    () =>
      [
        { id: 0, url: "/", label: "Trang chủ", icon: <HomeIcon /> },
        {
          id: 4,
          url: "/feed",
          label: "Feed",
          icon: <RssFeedIcon />,
          isNew: true,
        },
        {
          id: 1,
          url: "/tin-nhan",
          label: "Tin nhắn",
          icon: <ChatBubbleOutlineIcon />,
          count: unReadTopics,
        },
        {
          id: 2,
          url: "/order/cart.html",
          label: "Giỏ hàng",
          icon: <ShoppingCartOutlinedIcon />,
        },
        { id: 3, url: "/user", label: "Tài khoản", icon: <FaceIcon /> },
      ].filter(Boolean),
    [unReadTopics]
  );

  return (
    <div className={`footerbar grid grid-cols-5 max-w-screen-md mx-auto`}>
      {navData.map(nav => (
        <NavigationItem
          {...nav}
          active={Router.asPath === nav.url}
          key={nav.id}
        />
      ))}
    </div>
  );
};

export default React.memo(FooterBar);
